import React from 'react'
import CreateModel from './createModel'
import CreateHeader from './createHeader'

const CreateModelMain = () => {
  return (
    <div>
        <CreateHeader/>
        <CreateModel/>

    </div>
  )
}

export default CreateModelMain
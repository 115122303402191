import React, { useState } from 'react';
import "./wishlist.css";
import img1 from"../../images/category/fox.svg";
import plus from "../../images/icons/25.svg";
import tick from "../../images/icons/26.svg";
import like from  "../../images/icons/20.svg";
import img3 from "../../images/icons/21.svg";
import unlike from "../../images/icons/24.svg";
import img5 from "../../images/icons/23.svg";
import { useEffect } from 'react';
import { BASE_URL } from '../../../../constant';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Model from '../modelViewer';
import { Link } from 'react-router-dom';
import EmptyCartUser from '../Cart/emptyCartUser';
import EmptyCartWishlist from './emptyWishlistUser';
 
 
function WishlistCard({wish}) {
  const [cart, setCart] = useState();
  const [cartCount, setCartCount] = useState(0);
  const [imgSrc, setImgSrc] = useState(like);
  const [imgPlus, setImgPlus] = useState(() => {
    // Check if the value is already stored in localStorage
    const storedImgPlus = localStorage.getItem("imgplus");
    // Return the stored value if it exists, otherwise return the default value
    return storedImgPlus ? storedImgPlus : plus;
  });
  const { currentUser } = useSelector((state) => state.user);
  const prodId= wish.productId
  const prodID = wish.productId._id
  const catId = wish.productId.category
  const userid = currentUser ? currentUser._id : null;
  const [isLiked, setIsLiked] = useState(false);
 
 
 
 
  const handleWishlist = async () => {
    const newIsLiked = !isLiked; // Toggle immediately
    setIsLiked(newIsLiked); // Update state immediately
 
    const wishlistInfo = {
      userId: userid,
      productId: prodId,
      isLike: newIsLiked, // Use newIsLiked instead of imgSrc
    };
 
    try {
      // console.log("wishlistInfo", wishlistInfo);
      const res = await fetch(`${BASE_URL}/wishlist/likeProduct`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(wishlistInfo),
      });
      // console.log("response wishlist", res);
 
      if (res.ok) {
        const data = await res.json(); // Parse response body
        // console.log("wishlist response data", data);
        if (newIsLiked)
          {
          // toast("Product added to wishlist", {
          //   icon: "👏",
          //   style: {
          //     borderRadius: "10px",
          //     background: "#333",
          //     color: "#fff",
          //   },
          // });
          setImgSrc(like);
        }
        else
        {
          // toast("Product removed from wishlist", {
          //   icon: "👏",
          //   style: {
          //     borderRadius: "10px",
          //     background: "#333",
          //     color: "#fff",
          //   },
          // });
          setImgSrc(unlike);
        }
      } else {
        const errorData = await res.json(); // Parse error response body
        // console.error("wishlist error data", errorData);
        alert(errorData.message); // Display error message
        setIsLiked(!newIsLiked); // Revert state if API call fails
      }
    } catch (error) {
      // console.error(error.message);
      // toast.error("Failed to add/remove item to/from wishlist");
      setIsLiked(!newIsLiked); // Revert state if API call fails
    }
  };

  const cartinfo = {
    userId: userid,
    productId: prodID,
  };

  useEffect(() => {
    localStorage.setItem("imgPlus", imgPlus);
  }, [imgPlus]);

  const handleCart = async () => {
    try {
      if (!currentUser) {
        // Handle the scenario where the user is not logged in
        toast("Please login to add items to cart", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return;
      }
      if (imgPlus === tick) {
        const productId = prodID;
        // console.log(prodID);
        const deleteRes = await fetch(`${BASE_URL}/cart/RemoveItemFromCart`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ productId }), // Wrap productId in an object
        });

        if (deleteRes.ok) {
          //   alert("Item removed from cart successfully");
          toast("Item removed from cart successfully", {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setImgPlus(plus); // Change the icon back to plus
          setCartCount(cartCount - 1);
          // console.log(cartCount);
        } else {
          throw new Error("Failed to delete item from cart");
        }
      } else {
        const res = await fetch(`${BASE_URL}/cart/createCart`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartinfo),
        });

        if (res.ok) {
          const data = await res.json();
          //   alert(data.message);
          toast(data.message, {
            icon: "👏",
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
          });
          setCart(data); // Assuming data contains cart information
          setImgPlus(tick);
          setCartCount(cartCount + 1);
          // console.log(cartCount);
        } else {
          throw new Error("Failed to add item to cart");
        }
      }
    } catch (error) {
      // console.error(error.message);
      toast("Already in cart", {
        icon: "👏",
        style: {
          borderRadius: "5px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  };
 
 
 
// const handleClick = () => {
//     setImgSrc(imgSrc === img2 ? img4 : img2);
//   };
 
const handleClick01 = () => {
    setImgPlus(imgPlus === plus ? tick : plus);
  };
 
 
  

  
   
  return (
    <div className="card-wishlist">
      <div className="box-wishlist">
        <div className='image-wishlist'>
       
        <Link to={`/assetsPopup/${prodId._id}/${catId}`} >
            <img src={wish.previewImageUrls}/>
        </Link>
   
     
        </div>
        <div className='total-content-assets'>
        <div className='content-wishlist'>
          <div className='title-wishlist01'>
          <p>{wish.productId ? wish.productId.assetName : "Unknown Asset"}</p>
            <img src={imgSrc} alt="like" onClick={handleWishlist}/>
          </div>
          <div className='title-wishlist02'>
            <div className='flex'>
            <img src={img3} alt="size"/>
            <p>{wish.productId ? wish.productId.totalFileSize : "N/A"}</p>
            </div>
            <div className='flex'>
            <img src={img5} alt="price"/>
            <h4>{wish.productId ? wish.productId.price : "N/A"}</h4>
            </div>
          </div>
        </div>
        
          <div className="iconbox-wishlist">
            <img src={imgPlus} alt="Icon" onClick={handleCart}/>
          </div>
       
        </div>
      </div>
    </div>
  );
}
 
 
function Wishlist() {
  const { currentUser } = useSelector((state) => state.user);
  const [userWishlist, setUserWishlist] = useState([]);
 
  const id= currentUser._id;
  // console.log(id)
 
 
 
  useEffect(() => {
    const fetchUserWishlist = async () => {
      try {
        const res = await fetch(`${BASE_URL}/wishlist/getParticularLikeProducts?id=${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            "Content-Type": "application/json",
          },
        });
        // console.log(res)
        if (res.ok) {
          const data = await res.json();
          setUserWishlist(data.data);
          console.log("user wishlist data", data.data);
        } else {
          throw new Error("Failed to fetch user's wishlist");
        }
      } catch (error) {
        // console.error("Error fetching user's wishlist:", error.message);
        // Display error message to the user
      }
    };
   
    fetchUserWishlist();
  }, [id, BASE_URL, currentUser]);

 
  return (
    <div className="container-wishlist">
      <div className='container-wishlist-heading'>
        <h2>Wishlist ({userWishlist.length})</h2>
      </div>
      <div className='container-wishlist-main'>
      {userWishlist.length > 0 ? (
        userWishlist.map((wish)=>(<WishlistCard key={wish._id} wish={wish}/>))
      ):(
          <EmptyCartWishlist/>
      )}
      </div>
    </div>
  );
}
 
// {usercart.map((item) => (
//   <CartCard key={item._id} item={item} />
// ))}
 
export default Wishlist;
import React from 'react'
import "../contact/contact.css"
import "../privacy policy/privacyPolicy.css"

const ShippingPolicy = () => {
  return (
    <div className='privacy-policy-container'>
    <div className='privacy-policy-head'>
    <div className='privacy-policy-main'>
        <h1>Digital Delivery Policy</h1>
        <h2>1. Introduction</h2>
        <p>This Digital Delivery Policy outlines the terms and conditions regarding the delivery of digital 3D assets purchased from our website.</p>
        <h2>2. Delivery of Digital Products</h2>
        <p>Upon successful payment, digital products will be delivered via electronic means. There are no physical items shipped. Delivery of
            digital assets is instant and will be made available for download directly from your account.</p>
        <h2>3. Accessing Your Digital Purchases</h2>
            <p>Purchased 3D assets can be downloaded from the "Profile" section on our website. Log in to your account,
               navigate to your profile were you can find your order details, and click on the download link.</p>
          <h2>4. Download Issues</h2>
          <p>If you encounter any issues with downloading your purchased 3D assets, please contact our support team at <span className='contact-email'>corlmart@digamend.com</span>. Include your
             order number and a description of the issue, and we will assist you promptly.</p>
          <h2>5. Redownloads</h2>
          <p>Customers are allowed to redownload purchased 3D assets. If the download link expires or you need to download the files again,
             you can access them through your account or contact support for assistance.</p>
          <h2>6. File Format and Compatibility</h2>
          <p>Please ensure that you check the file format and compatibility of the 3D assets before making a purchase. All sales are final once the digital products have been downloaded.
             We provide detailed descriptions and previews to help you make an informed decision.</p>
        </div>
        </div>
        </div>
  )
}


export default ShippingPolicy
import React, { useEffect, useState } from "react";
import "./userProfile.css";
import back from "../../images/back.svg";
import SideBar from "../../common/sideBar";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../../constant";
 
const TabContent = ({ content }) => {
  if (!content || content.length === 0) {
    return <p>No data available</p>;
  }

 
  return (
    <div className="user-profile-purchase-table">
      {content.map((set, index) => (
        <div key={index} className="user-profile-purchase-table-content">
          {set.map((item, idx) => (
          <div key={idx} className="user-profile-purchase-table-content1">
          <h1>{item.label}:</h1>
          <p>{item.value}</p>
          </div>
          ))}
        </div>
      ))}
    </div>
  );
};
 
const AdminUserProfile = () => {
  const [activeItem, setActiveItem] = useState("userManagement");
  const [activeTab, setActiveTab] = useState("purchaseHistory");
  const { currentUser } = useSelector((state) => state.user);
  const [users, setUsers] = useState([]);
  const [purchase,setPurchase]= useState([]);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [cart,setCart]=useState([]);
  // const [favourite,setFavourite]=useState([]);
  const { userId } = useParams();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return "Invalid date";
    }
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  useEffect(() => {
    console.log(userId);
    const fetchUsers = async () => {
      try {
        const res = await fetch(`${BASE_URL}/user/getParticularUser?id=${userId}`, {
          method: "GET",
         
        });
        if (res.ok) {
          const data = await res.json();
          console.log(data.message)
          setUsers(data.message);
         console.log(users);
        } else {
          throw new Error("Failed to fetch users");
        }
      } catch (error) {
        console.error(error.message);
        // Handle error, show message to user, etc.
      }
    };
 
    if (currentUser && currentUser.isAdmin) {
      fetchUsers();
    }
  }, [currentUser]);
 
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };
 
 
 
  useEffect(() => {
    console.log(userId);
    const fetchUsersHistory = async () => {
      try {
        const res = await fetch(`${BASE_URL}/payment/getParticularUserHistory?userId=${userId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
   
          },
         
        });
        console.log(res);
        if (res.ok) {
          const data = await res.json();
          console.log(data.message)
          setPurchaseHistory(data.message);
         
        } else {
          throw new Error("Failed to fetch users");
        }
      } catch (error) {
        console.error(error.message);
        // Handle error, show message to user, etc.
      }
    };
 
    if (currentUser && currentUser.isAdmin) {
      fetchUsersHistory();
    }
  }, [currentUser]);
 
 
 
  useEffect(() => {
    const fetchTabData = async () => {
      try {
        if (activeTab === "purchaseHistory") {
          const res = await fetch(`${BASE_URL}/payment/getParticularUserHistory?userId=${userId}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
            },
          });
          if (res.ok) {
            const data = await res.json();
            setPurchaseHistory(data.message);
          } else {
            throw new Error("Failed to fetch purchase history");
          }
        } else if (activeTab === "favorite") {
          const res = await fetch(`${BASE_URL}/wishlist/getParticularLikeProducts?id=${userId}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
            },
          });
          if (res.ok) {
            const data = await res.json();
            setFavorites(data.data);
            console.log("user wishlist data", data.data);
          } else {
            throw new Error("Failed to fetch user's wishlist");
          }
        } else if (activeTab === "cart") {
          const res = await fetch(`${BASE_URL}/cart/getUserCart?userId=${userId}`, {
            method: "GET",
          });
          if (res.ok) {
            const data = await res.json();
            setCart(Array.isArray(data.data) ? data.data : []);
          } else {
            throw new Error("Failed to fetch cart");
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    if (currentUser && currentUser.isAdmin) {
      fetchTabData();
    }
  }, [currentUser, userId, activeTab]);



  const tabData = {
    purchaseHistory: purchaseHistory.map((item) => [
      { label: "Asset Name", value: item.assetName },
      { label: "ID", value: item.id },
      { label: "Purchase Date", value: formatDate(item.date) },
      { label: "Price", value: `₹${item.price}` }
    ]),
    favorite: favorites.map((item) =>[
      { label: "Asset Name", value: item.productId.assetName },
      { label: "ID", value: item.productId.assetID },
      { label: "Price", value: `₹${item.productId.price}` }
    ]),
    
    cart: cart.map((item) => [
     
      { label: "Asset Name", value: item.productId.assetName },
      { label: "ID", value: item.productId._id },
      { label: "Added On", value: formatDate(item.createdAt) },
      { label: "Price", value: `₹${item.productId.price}` }
    ])
  };

 
  return (
    <div className="user-profile-container">
      <SideBar setActiveItem={handleItemClick} activeItem={activeItem} />
      <div className="user-profile-main">
        <div className="user-profile-content">
          <div className="user-profile-content-heading">
           <Link to ="/userManagement" ><img src={back} alt="Back" /></Link>
            <h1>Profile</h1>
          </div>
          <div className="user-profile-content-details-main">
            <div className="user-profile-content-details">
              <div className="user-profile-content-details-profile">
                <h1>User Name</h1>
                <p>{users.name}</p>
              </div>
              <div className="user-profile-content-details-profile">
                <h1>Email ID</h1>
                <p>{users.email}</p>
              </div>
              <div className="user-profile-content-details-profile">
                <h1>Phone Number</h1>
                <p>{users.phone}</p>
              </div>
            </div>
            <div className="user-profile-purchase-details">
              <div className="user-profile-toggle">
                {Object.keys(tabData).map((key) => (
                  <button
                    key={key}
                    className={activeTab === key ? "active" : ""}
                    onClick={() => setActiveTab(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </button>
                ))}
              </div>
              {activeTab && <TabContent content={tabData[activeTab]} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default AdminUserProfile;
import React from 'react'
import "../Cart/emptyCart.css"
import cart from "../../images/icons/36.svg"
import { Link } from 'react-router-dom'

const EmptyCartWishlist = () => {
  return (
    <div className='empty-cart-container'>
        

        <div className='empty-cart-main'>
            
                <div className='empty-cart-user-section'>
                    <div className='empty-cart-image'>
                        <img src={cart} />
                        </div>
                        <div className='empty-cart-user-text'>
                           <h3>Wishlist is empty</h3>
                        </div>
                    
                        

                </div>
        </div>

    </div>
  )
}

export default EmptyCartWishlist
import React, { useState, useEffect } from "react";
import "./payment.css";
import profileimg from "../../image/animoji.svg";
import cartimg from "../../images/category/fox.svg";
import rupee from "../../images/icons/23.svg";
import visa from "../../image/Visa.svg";
import mastercard from "../../image/Mastercard.svg";
import discover from "../../image/Discover.svg";
import amex from "../../image/Amex.svg";
import { BASE_URL } from "../../../../constant";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Razorpay from 'razorpay';
// const Razorpay = require('razorpay');

const Payment = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [hasPurchased, setHasPurchased] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, SetLastName] = useState("");
  const [addressLine_1, setAddressLine1] = useState("");
  const [addressLine_2, setAddressLine2] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipcode] = useState("");
  const { currentUser } = useSelector((state) => state.user);
  const [usercart, setUserCart] = useState([]);
  const userId = currentUser._id;
  const [userdata, setUserdata] = useState("");
  // console.log(userId);
  

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const navigate = useNavigate();

  const handlecartEdit = () =>{
    navigate("/cart")
  }

  const BillingAddress = async (e) => {
    e.preventDefault();

    const form = {
      userId,
      firstName,
      lastName,
      addressLine_1,
      addressLine_2,
      country,
      region,
      city,
      zipCode,
    };

    try {
      const response = await fetch(`${BASE_URL}/billing/createBilling`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Billing Address not submitted");
      }
      // console.log(response.data);

      
      setHasPurchased(true);
      setAddressLine1(1); 
      setCurrentStep(2)// Navigate to the payment page using useNavigate
    } catch (error) {
      // console.error("Error:", error);
      alert(error.message || "Please try again."); // Display alert message for error
    }
  };

  const handleEdit = () => {
    setCurrentStep(1); // Set current step to 1
    setHasPurchased(false); // Ensure that the form is displayed
  };
  

  const handlePurchase = () => {
    setHasPurchased(true);
    setCurrentStep(2);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Check if currentUser exists before making the request
        if (!currentUser) return;

        const res = await fetch(
          `${BASE_URL}/user/getParticularUser?id=${currentUser._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
            },
          }
        );
        if (!res.ok) {
          throw new Error("Failed to fetch user details");
        }
        const data = await res.json();
        if (data.status !== 200) {
          throw new Error(data.message);
        }
        // Update state or dispatch action to store user details
      } catch (error) {
        // console.error("Error:", error);
        // Handle error
      }
    };

    fetchUserDetails();
  }, [currentUser]);


  // const handleGuestBuyNow = () => {
  //   const name = document.getElementById("name").value.trim();
  //   const phoneNumber = document.getElementById("phoneNumber").value.trim();
  //   if (name && phoneNumber) {
  //     console.log("Buying now...");
  //     setHasPurchased(true);
  //     setCurrentStep(2);
  //     handleClosePopup();
  //   } else {
  //     alert("Please fill in all required fields.");
  //   }
  // };


  const totalValue = usercart.reduce((total, item) => {
    // Convert price to number before adding to total
    const price = item.productId ? parseFloat(item.productId.price) || 0 : 0;

    return total + price;
  }, 0);

  const GST = totalValue * 0.2;
  const totalWithTaxes = Math.round(totalValue + GST);

  const amount = totalWithTaxes * 100;
  const currency = "INR";
  const receiptId = "receipt1";

  const username = currentUser?.name;
  // console.log("username", username)



  const paymentHandler = async (e) => {
    if (usercart.length === 0) {
      alert("Shopping cart is empty. Add items to cart before proceeding.");
      return;
    }

    const response = await fetch(`${BASE_URL}/payment/order`, {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
       
      }),
      headers: {
        "content-Type": "application/json",
      },
      
    });
   
    // console.log(response);
    const order = await response.json();
    // console.log(order);

    var options = {
      key: "rzp_live_JSEJeuTaSz8pZ5", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: "Digamend Technolody Solutions", //your business name
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          userId,
          username,
          usercart,
          totalValue,
          GST,
          totalWithTaxes,
          ...response,
        };
        // console.log(body);
        const validateRes = await fetch(`${BASE_URL}/payment/ordervalidate`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await validateRes.json();
        // console.log(jsonRes);
        window.location.reload();
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name:  currentUser?.name, //your customer's name
        email: currentUser?.email,
        contact: currentUser?.phone, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#ff7f50",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
    
  };

  useEffect(() => {
    const fetchUserCart = async () => {
      try {
        const res = await fetch(
          `${BASE_URL}/cart/getUserCart?userId=${currentUser._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.ok) {
          const data = await res.json();
          setUserCart(data.data);
          // console.log(data.data);
        } else {
          throw new Error("Failed to fetch user's cart");
        }
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchUserCart();
  }, []);

  // console.log(usercart);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await fetch(
          `${BASE_URL}/billing/getBilling?userId=${currentUser._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.ok) {
          const data = await res.json();
          setUserdata(data.message);
          // console.log(data.message)
          // console.log("userData", userdata);
          // console.log(userdata[0].firstName);
          if (data.message.length > 0) {
            setHasPurchased(true);
            setCurrentStep(2);
          }
        } else {
          throw new Error("Failed to fetch user's cart");
        }
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchUser();
  }, []);


  return (
    <div className="payment-container">
      <div className={`payment-main ${isPopupOpen ? "blur-background" : ""}`}>
        <div className="payment-section1">
          <div className="payment-section1-div1">
            <div
              className={`indicator-number ${
                currentStep === 1 ? "active-indicator" : ""
              }`}
            >
              1
            </div>
            <div className="indicator-text">Billing Address</div>
          </div>
          <hr />
          <div className="payment-section1-div1">
            <div
              className={`indicator-number ${
                currentStep === 2 ? "active-indicator" : ""
              }`}
            >
              2
            </div>
            <div className="indicator-text">Payment</div>
          </div>
        </div>

        {hasPurchased ? (
          <div className="account-details-main">
            <div className="account-details-section1">
              <div className="account-details-section1-div1">
                <h1>Account</h1>
                <hr />
                <div className="account-details-img">
                  <img
                    src="https://cdn-icons-png.flaticon.com/128/1144/1144709.png"
                    alt={<FontAwesomeIcon icon="fal fa-user-alt" />}
                  />
                  <h2>
                    {" "}
                    {userdata.length > 0
                ? `${userdata[0].firstName} ${userdata[0].lastName}`
                : `${currentUser.name}`}
                  </h2>
                </div>
              </div>
              <div className="account-details-section1-div2">
                <div className="account-shopping-cart">
                  <h1>Shopping Cart</h1>
                  <p onClick={handlecartEdit}>Edit</p>
                </div>
                <hr />

                {usercart.map((item, index) => (
                  <div className="account-shopping-details" key={index}>
                    <div className="account-shopping-img">
                      <img src={item.previewImageUrls} /> 
                      <h1>
                        {item.productId ? item.productId.assetName : "Unknown"}
                      </h1>
                    </div>
                    <div className="account-shopping-value">
                      <img src={rupee} alt="Rupee Symbol" />
                      <h1>{item.productId ? item.productId.price : "10"}</h1>
                    </div>
                  </div>
                ))}
              </div>

              <div className="account-details-section1-div3">
                <div className="account-details-cart">
                  <h1>Billing Address</h1>
                  <p onClick={handleEdit}>Edit</p>
                </div>
                <hr />
                <div className="account-details-address">
                  <p>
                 
              {userdata.length > 0
                ? `${userdata[0].firstName} ${userdata[0].lastName}`
                : `${currentUser.name}`}
                  </p>
                  <p>
                  {userdata.length > 0
                ? userdata[0].addressLine_1
                : currentUser.email}
                  </p>
                  <p>
                  {userdata.length > 0
                ? userdata[0].addressLine_2
                : currentUser.phone}
                  </p>
                  <p>
                  {userdata.length > 0 ? userdata[0].country : ""}
                  </p>
                  <p>
                    {userdata.length > 0 ? userdata[0].city : ""}
                  </p>
                  <p>
                    {userdata.length > 0 ? userdata[0].zipCode : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="account-details-section2">
              <div className="account-details-section2-heading">
                <h1>Account</h1>
              </div>
              <hr />
              <div className="account-details-section2-main">
                <div className="account-details-subtotal">
                  <p>Subtotal</p>
                  <p>₹ {totalValue.toFixed(2)}</p>
                </div>
                <hr />
                <div className="account-details-taxes">
                  <p>Tax</p>
                  <p>₹ {GST}</p>
                </div>
                {/* <div className="account-details-taxes">
                  <p>GST </p>
                  <p>20%</p>
                </div> */}
                <hr />
                <div className="account-details-total">
                  <p>Grand Total</p>
                  <p>₹ {totalWithTaxes}
                  <br/> <span style={{fontSize:12, lineHeight:0}}>(Incl. of GST)</span></p>
                </div>
                <div className="account-details-paynow">
                  <button
                    className="account-details-button"
                    onClick={paymentHandler}
                  >
                    Pay Now
                  </button>
                </div>
                <div className="account-details-card">
                  <img src={visa} />
                  <img src={mastercard} />
                  <img src={discover} />
                  <img src={amex} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="payment-section2">
              <div className="payment-section2-div2">
                <div className="billing-form-heading">
                  <h1>Billing Address</h1>
                  <hr />
                </div>
                <div className="billing-form-content">
                  <div className="billing-row">
                    <div className="billing-form-group">
                      <label htmlFor="firstName" className="bill-form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        className="bill-input"
                        required
                      />
                    </div>

                    <div className="billing-form-group">
                      <label htmlFor="lastName" className="bill-form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={(e) => SetLastName(e.target.value)}
                        className="bill-input"
                        required
                      />
                    </div>
                  </div>

                  <div className="billing-form-group">
                    <label htmlFor="addressLine1" className="bill-form-label">
                      Address Line 1
                    </label>
                    <input
                      type="text"
                      id="addressLine1"
                      name="addressLine_1"
                      className="bill-input"
                      onChange={(e) => setAddressLine1(e.target.value)}
                      required
                    />
                  </div>

                  <div className="billing-form-group">
                    <label htmlFor="addressLine2" className="bill-form-label">
                      Address Line 2
                    </label>
                    <input
                      type="text"
                      id="addressLine2"
                      name="addressLine_2"
                      onChange={(e) => setAddressLine2(e.target.value)}
                      className="bill-input"
                      required
                    />
                  </div>

                  <div className="billing-row">
                    <div className="billing-form-group">
                      <label htmlFor="country" className="bill-form-label">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        onChange={(e) => setCountry(e.target.value)}
                        className="bill-input-select"
                        required
                      >
                        <option className="bill-input" value="">
                          Select Country
                        </option>
                        <option className="bill-input" value="US">
                          United States
                        </option>
                        <option className="bill-input" value="IN">
                          India
                        </option>
                        <option className="bill-input" value="CA">
                          Canada
                        </option>
                        <option className="bill-input" value="UK">
                          United Kingdom
                        </option>
                        <option className="bill-input" value="AU">
                          Australia
                        </option>
                      </select>
                    </div>

                    <div className="billing-form-group">
                      <label htmlFor="region" className="bill-form-label">
                        Region/Province
                      </label>
                      <input
                        type="text"
                        id="region"
                        name="region"
                        onChange={(e) => setRegion(e.target.value)}
                        className="bill-input"
                        required
                      />
                    </div>
                  </div>

                  <div className="billing-row">
                    <div className="billing-form-group">
                      <label htmlFor="city" className="bill-form-label">
                        City
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        onChange={(e) => setCity(e.target.value)}
                        className="bill-input"
                        required
                      />
                    </div>

                    <div className="billing-form-group">
                      <label htmlFor="zipcode" className="bill-form-label">
                        Zip Code
                      </label>
                      <input
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        onChange={(e) => setZipcode(e.target.value)}
                        className="bill-input"
                        required
                      />
                    </div>
                  </div>

                  <div className="billing-form-button">
                    <button
                      type="button"
                      className="billing-button"
                      onClick={BillingAddress}
                    >
                      Next
                    </button>
                    <button
                      type="button"
                      className="billing-button-skip"
                      onClick={handlePurchase}
                    >
                      Skip
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
     
    </div>
  );
};

export default Payment;

import React ,{useState, useEffect} from "react";
import "./landing02.css"
import img1 from "../../image/fox.svg";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../../constant";
import axios from "axios"
 
const Our3DModels = () => {
  const [categoryDataList, setCategoryDataList] = useState([]);
  useEffect(() => {
    // Fetch category data from the backend
    axios
      .get(`${BASE_URL}/category/getAllCategory`)
      .then((response) => {
        // Access the array of categories from the response data
        const categories = response.data;
        
        setCategoryDataList(categories.allCategories);
      })
      .catch((error) => {
        console.error("Error fetching category data:", error);
      });
  }, []);



  const sortedCategories = categoryDataList
  .slice() // Create a copy of the array
  .sort((a, b) => {
    const nameA = a.categoryName || "";
    const nameB = b.categoryName || "";

    // Check if nameA or nameB starts with 'o' or 'O'
    const isAO = nameA.toLowerCase().startsWith('o');
    const isBO = nameB.toLowerCase().startsWith('o');

    if (isAO && isBO) return 0; // Both start with 'o', keep their order the same
    if (isAO) return 1; // nameA starts with 'o', should come after nameB
    if (isBO) return -1; // nameB starts with 'o', should come after nameA

    return nameA.localeCompare(nameB);
  })
  .slice(0, 8);
  
  

  return (
    <div className="container01">
      <div className="card-container" >
        <div className="card-con-heading">
          <div className="card-con01">
            <h1>Our 3D Models</h1>
            
            <Link to={"/category"}><p>View All</p></Link>
          </div>
          <div className="card-con02">
          
          {sortedCategories.map((categoryData , index) => (
            <Link to={`/assets/${categoryData._id}`} key={index}>
            <div className="card">
              <img src={categoryData.imageUrl} />
              <p>{categoryData.categoryName}</p>
            </div>
            </Link>
          ))}
          
            
          </div>
        </div>
      </div>
      <div className="detail-container">
        <div className="detail-con1">
          <h3>500+</h3>
          <p>3D Assets</p>
        </div>
        <div className="detail-con1">
          <h3>400+</h3>
          <p>Downloads</p>
        </div>
        <div className="detail-con1">
          <h3>15+</h3>
          <p>Categories</p>
        </div>
      </div>
    </div>
  );
};
 
export default Our3DModels;
import React, { useEffect, useState } from "react";
import "./cart.css";
import { useSelector } from "react-redux";
import img1 from "../../image/fox.svg";
import unlike from "../../image/010.svg";
import like from "../../image/011 (1).svg";
import img5 from "../../image/007.svg";
import img6 from "../../image/009.svg";
import { BASE_URL } from "../../../../constant";
import { toast } from "react-toastify";
import Model from "../modelViewer";
import { Link , useNavigate} from "react-router-dom";
import EmptyCart from "./emptyCart";
import EmptyCartUser from "./emptyCartUser";
 
function CartCard({ item }) {
  // Pass 'item' as a prop to get details of the product
  const { currentUser } = useSelector((state) => state.user);
  const [imgSrc, setImgSrc] = useState(unlike);
  const [isLiked, setIsLiked] = useState(false);
  const userid = currentUser ? currentUser._id : null;
  const catId =item.productId.category;
 
  // const handleClick = () => {
  //   setImgSrc(imgSrc === img4 ? img2 : img4);
  // };
 
  const removeItem = async () => {
    // console.log(item);
    const productId = item.productId; // Get the productId from the 'item' prop
    // console.log("prodId", productId);
    try {
      const deleteRes = await fetch(`${BASE_URL}/cart/RemoveItemFromCart`, {
        // Corrected endpoint name
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ productId }),
      });
      // console.log("productIdd", productId);
 
      // console.log(deleteRes);
      window.location.reload();
      // Optionally, you can update the cart after successful removal
      // } else {
      //     throw new Error('Failed to delete item from cart');
      // }
    } catch (error) {
      // console.error(error.message);
      toast.error("Failed to update cart");
    }
  };

  if (!item || !item.productId) {
    return null; // Or render some fallback UI
  }
 
  
  const handleWishlist = async () => {
    const productId = item.productId
    const wishlistInfo = {
      userId: userid,
      productId: productId,
      isLike: imgSrc === unlike ? true : false,
    };

    try {
      // console.log("wishlistInfo", wishlistInfo);
      const res = await fetch(`${BASE_URL}/wishlist/likeProduct`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(wishlistInfo),
      });
      // console.log("response wishlist", res);

      if (res.status === 200) {
        if (isLiked) {
          // alert('Product removed from wishlist');
          toast("Product removed from wishlist", {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setImgSrc(unlike);
        } else {
          // alert('Product added to wishlist');
          toast("Product added to wishlist", {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setImgSrc(like);
        }

        setIsLiked(!isLiked);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      // console.error(error.message);
      toast.error("Failed to add/remove item to/from wishlist");
    }
  };

 
  return (
    <div className="card-cart">
      <div className="card-cart-main">
        <div className="card-cart-main-content">
          <div
            className="image-cart">
            <Link to={`/assetsPopup/${item.productId._id}/${catId}`}>
              <img src={item.previewImageUrls}/>
            </Link>
          </div>
          <div className="content-cart">
            <div className="title-cart01">
              <p>{item.productId.assetName}</p>
            </div>
            <div className="title-cart02">
              <p>Format:</p>
              <h3>{item.productId.fileFormats.join(', ')}</h3>
            </div>
            <div className="title-cart03">
              <h2>$</h2>
              <h3>{item.productId.price}</h3>
            </div>
            <div className="title-cart04">
              <img src={imgSrc} alt="fav" onClick={handleWishlist} />
              <p onClick={removeItem}>Remove</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}
 
function OrderCard({ usercart }) {
  const navigate = useNavigate();
  if (!Array.isArray(usercart) || usercart.length === 0) {
    return <div className="order-cart" style={{color:"white"}}>Your cart is empty.</div>;
  }


  const totalValue = usercart.reduce((total, item) => {
    if (item.productId && item.productId.price) {
      const price = parseFloat(item.productId.price) || 0;
      return total + price;
    }
    return total;
  }, 0);
 
  
  // console.log("totalValue:", totalValue);

 
  const handleBuyNowClick = () => {
    navigate("/payment");
  };

  return (
    <div className="order-cart">
      <div className="order-item01">
        <h4>Order Value</h4>
        <p>₹ {totalValue.toFixed(2)}</p>
      </div>
      <hr />
      <div className="order-item02">
        <h4>Total</h4>
        <p>₹ {totalValue.toFixed(2)}</p>
      </div>
      <div className="checkout" onClick={handleBuyNowClick}>
        <p >Continue to Checkout</p>
      </div>
    </div>
  );
}
 
function CartList() {
  const { currentUser } = useSelector((state) => state.user);
  const [usercart, setUserCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const fetchUserCart = async () => {
      try {
        const res = await fetch(
          `${BASE_URL}/cart/getUserCart?userId=${currentUser._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.ok) {
          const data = await res.json();
          // console.log(data)
          // Ensure the fetched data is an array
          setUserCart(Array.isArray(data.data) ? data.data : []);
        } else {
          throw new Error("Failed to fetch user's cart");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserCart();
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }


  const totalProductIdsCount = usercart.length;
  if (totalProductIdsCount === 0) {
    return <EmptyCartUser />;
  }
 
  return (
    <div className="container-cart">
      <div className="main-cart">
        <h5>My Cart ({totalProductIdsCount})</h5>
        <div className="container-cart-main">
          <div className="container-cart-main01">
            {usercart.map((item) => (
              <CartCard key={item._id} item={item} />
            ))}
          </div>
          <div className="container-cart-main02">
            <OrderCard usercart={usercart} />
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default CartList;
import React, { createContext, useState } from 'react';

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const initialImages = {
    frontFacingPhoto: null,
    leftFacingPhoto: null,
    rightFacingPhoto: null,
    fullFrontFacingPhoto: null,
    fullBackFacingPhoto: null,
    fullPerspectivePhoto: null,
  };

  const [images, setImages] = useState(initialImages);

  const updateImage = (key, image, imageName) => {
    setImages((prevImages) => ({
      ...prevImages,
      [key]: { image, imageName },
    }));
  };

  const clearAllImages = () => {
    setImages(initialImages);
  };

  return (
    <ImageContext.Provider value={{ images, updateImage, clearAllImages }}>
      {children}
    </ImageContext.Provider>
  );
};

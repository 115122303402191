import React, { useState, useEffect } from "react";
import "./assetsPopup.css";
import { toast } from "react-toastify";
import {useFunc} from "../../../../redux/user/helperFunctions"
import image from "../../images/category/27.svg";
import download from "../../images/icons/21.svg";
import rupee from "../../images/icons/23.svg";
import img01 from "../../images/icons/28.svg";
import img02 from "../../images/icons/29.svg";
import img03 from "../../images/icons/30.svg";
import plus from "../../images/icons/32.svg";
import like from "../../images/icons/31.svg";
import tick from "../../images/icons/33.svg";
import unlike from "../../images/icons/34.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { BASE_URL } from "../../../../constant";
import Model from "../modelViewer";
import { useNavigate } from "react-router-dom";
import like1 from "../../images/icons/20.svg";
import unlike1 from "../../images/icons/24.svg";


const SimilarAssets = ({data}) =>{
 const { currentUser } = useSelector((state) => state.user);
  const [imgLike, setImgLike] = useState(unlike1);
  const [imgPlus, setImgPlus] = useState(plus);
  const [cartCount, setCartCount] = useState(0);
  const [cart, setCart] = useState();
  const navigate = useNavigate();
  const userid = currentUser ? currentUser._id : null;
  const ID = useParams();
  // console.log("assetId", ID);
  const id = ID.assetId;
  const catId = ID.catID
  const [isLiked, setIsLiked] = useState(false);
  


  const handleLinkClick = (id) => {
    // Remove the '#' character from the ID
    const cleanId = id.replace('#', '');
    // Construct the hash link with the cleaned ID
    const hashLink = `#${cleanId}`;
    // Scroll to the element with the corresponding ID
    const element = document.querySelector(hashLink);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };



  const handleWishlistSimilar = async () => {
    const wishlistInfo = {
      userId: userid,
      productId: data._id,
      isLike: imgLike === unlike1 ? true : false,
    };
  
    try {
      // console.log("wishlistInfo", wishlistInfo);
      const res = await fetch(`${BASE_URL}/wishlist/likeProduct`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${currentUser.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(wishlistInfo),
      });
      // console.log("response wishlist", res);
  
      if (res.status === 200) {
        if (isLiked) {
          toast("Product removed from wishlist", {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setImgLike(unlike1);
        } else {
          toast("Product added to wishlist", {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setImgLike(like1);
        }
  
        setIsLiked(!isLiked);
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      // console.error(error.message);
      toast.error("Failed to add/remove item to/from wishlist");
    }
  };



  const cartSimilarinfo = {
    userId: userid,
    productId: data._id,
  };
  // console.log(cartSimilarinfo);

  const handleSimilarCart = async () => {
    try {
      if (!currentUser) {
        toast("Please login to add items to cart", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return;
      }
      
      if (imgPlus === tick) {
        const productId = data._id;
  
        const deleteRes = await fetch(`${BASE_URL}/cart/RemoveItemFromCart`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ productId }), // Wrap productId in an object
        });
  
        if (deleteRes.ok) {
          toast("Item removed from cart successfully", {
            icon: "👏",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setImgPlus(plus); // Change the icon back to plus
          setCartCount(cartCount - 1);
          // console.log(cartCount);
        } else {
          throw new Error("Failed to delete item from cart");
        }
      } else {
        const res = await fetch(`${BASE_URL}/cart/createCart`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${currentUser.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartSimilarinfo),
        });
  
        if (res.ok) {
          const data = await res.json();
          toast(data.message, {
            icon: "👏",
            style: {
              borderRadius: "5px",
              background: "#333",
              color: "#fff",
            },
          });
          setCart(data); // Assuming data contains cart information
          setImgPlus(tick);
          setCartCount(cartCount + 1);
          // console.log(cartCount);
        } else if (res.status === 406) {
          const errorData = await res.json();
          throw new Error(`Failed to add item to cart: ${errorData.message}`);
        } else {
          throw new Error("Failed to add item to cart");
        }
      }
    } catch (error) {
      // console.error(error.message);
      toast(`Error: ${error.message}`, {
        icon: "🚫",
        style: {
          borderRadius: "5px",
          background: "#f44336",
          color: "#fff",
        },
      });
    }
  };
  



return(
   
            <div className="similar-assets-list">
                <div className="card-assets" >
                  <div className="box-assets">
                    <div className="image-assets">
                    <Link to={`/assetsPopup/${data._id}/${catId}`} onClick={() => handleLinkClick(data._id)}>
                      <img src={data.imageUrl} />
                      </Link>
                    </div>
                    <div className="total-content-assets">
                    <div className="content-assets">
                      <div className="title-assets01">
                        <p>{data.assetName}</p>
                        <img src={imgLike} alt="Like" onClick={handleWishlistSimilar} />
                      </div>
                      <div className="title-assets02">
                        <img src={download} alt="Download" />
                        <p>{data.totalFileSize}</p>
                        <img src={rupee} alt="Rupee" />
                        <h4>{data.price}</h4>
                      </div>
                    </div>
                  
                      <div className="iconbox-assets">
                        <img src={imgPlus} onClick={handleSimilarCart}  alt="Plus" />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          
);
}

const AssetDetails =({ondownloadclick,particularsimilarAssets})=>{
    // const [particularsimilarAssets, setParticularsimilarAssets] = useState([]);
    const { currentUser } = useSelector((state) => state.user);
    const [imgSrc, setImgSrc] = useState(unlike);
    const userid = currentUser ? currentUser._id : null;
    const ID = useParams();
    // console.log("assetId", ID);
    const id = ID.assetId;
    const catId = ID.catID;
    const [imgPlus, setImgPlus] = useState(plus);
    const [isLiked, setIsLiked] = useState(false);
    const navigate = useNavigate();
    const [cartCount, setCartCount] = useState(0);
    const [cart, setCart] = useState();
    const { isLoggedIn } = useFunc();



const handleLogin =()=>{
  navigate("/login");
}

      const handleBuynow = async () => {
        try {
          const cartinfo = {
            userId: userid,
            productId: id,
          };
          // console.log(cartinfo);
          const res = await fetch(`${BASE_URL}/cart/createCart`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(cartinfo),
          });
    
          // console.log(res);
    
          navigate("/payment");
        } catch (error) {
          // console.error("Error occurred during fetch:", error);
         
        }
      };
    

      const handleWishlist = async () => {
        const wishlistInfo = {
          userId: userid,
          productId: id,
          isLike: imgSrc === unlike ? true : false,
        };
    
        try {
          // console.log("wishlistInfo", wishlistInfo);
          const res = await fetch(`${BASE_URL}/wishlist/likeProduct`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(wishlistInfo),
          });
          // console.log("response wishlist", res);
    
          if (res.status === 200) {
            if (isLiked) {
              // alert('Product removed from wishlist');
              toast("Product removed from wishlist", {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              setImgSrc(unlike);
            } else {
              // alert('Product added to wishlist');
              toast("Product added to wishlist", {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              setImgSrc(like);
            }
    
            setIsLiked(!isLiked);
          } else {
            alert(res.data.message);
          }
        } catch (error) {
          // console.error(error.message);
          toast.error("Failed to add/remove item to/from wishlist");
        }
      };
    

      useEffect(() => {
        localStorage.setItem("imgPlus", imgPlus);
      }, [imgPlus]);
    
      const cartinfo = {
        userId: userid,
        productId: particularsimilarAssets.id,
      };
      // console.log(cartinfo);
    
      const handleCart = async () => {
        try {
          if (!currentUser) {
            toast("Please login to add items to cart", {
              icon: "👏",
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
            return;
          }
          
          if (imgPlus === tick) {
            const productId = particularsimilarAssets.id;
      
            const deleteRes = await fetch(`${BASE_URL}/cart/RemoveItemFromCart`, {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ productId }), // Wrap productId in an object
            });
      
            if (deleteRes.ok) {
              toast("Item removed from cart successfully", {
                icon: "👏",
                style: {
                  borderRadius: "10px",
                  background: "#333",
                  color: "#fff",
                },
              });
              setImgPlus(plus); // Change the icon back to plus
              setCartCount(cartCount - 1);
              // console.log(cartCount);
            } else {
              throw new Error("Failed to delete item from cart");
            }
          } else {
            const res = await fetch(`${BASE_URL}/cart/createCart`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${currentUser.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(cartinfo),
            });
      
            if (res.ok) {
              const data = await res.json();
              toast(data.message, {
                icon: "👏",
                style: {
                  borderRadius: "5px",
                  background: "#333",
                  color: "#fff",
                },
              });
              setCart(data); // Assuming data contains cart information
              setImgPlus(tick);
              setCartCount(cartCount + 1);
              // console.log(cartCount);
            } else if (res.status === 406) {
              const errorData = await res.json();
              throw new Error(`Failed to add item to cart: ${errorData.message}`);
            } else {
              throw new Error("Failed to add item to cart");
            }
          }
        } catch (error) {
          // console.error(error.message);
          toast(`Error: ${error.message}`, {
            icon: "🚫",
            style: {
              borderRadius: "5px",
              background: "#f44336",
              color: "#fff",
            },
          });
        }
      };
    
    
    return(
        <div className="assets-popup-section2">
        <div className="assets-popup-heading">
          <h1>{particularsimilarAssets.assetName}</h1>
        </div>
        <div className="assets-popup-value">
          
          <h1>₹ {particularsimilarAssets.price}</h1>
        </div>
        <div className="assets-popup-buy">
          {particularsimilarAssets.price === "0" ? (
            isLoggedIn ? (
            <button onClick={ondownloadclick}>Download</button>
            ):(
              <button onClick={handleLogin}>Download</button>
            )
          ) : (
            isLoggedIn ? (
              <button onClick={handleBuynow}>Buy Now</button>
              ):(
                <button onClick={handleLogin}>Buy Now</button>
              )
          )}

          <img src={imgPlus} onClick={handleCart} alt="Plus" />
          <img src={imgSrc} alt="Image" onClick={handleWishlist} />
        </div>
        <div className="assets-popup-description1">
          <img src={img01} alt="Secure Payment" />
          <p>Secure Payment</p>
        </div>
        <div className="assets-popup-description1">
          <img src={img02} alt="100% Support" />
          <p>100% Support</p>
        </div>
        <div className="assets-popup-description1">
          <img src={img03} alt="Access to future version" />
          <p>Access to future version</p>
        </div>

        <div className="assets-popup-format-box">
          <div className="assets-popup-format">
            <h1>Format:</h1>
            
              {particularsimilarAssets.fileFormats &&
                particularsimilarAssets.fileFormats.map((file, index) => (
                  <div key={index}>
                    <p>{file}</p>
                  </div>
                ))}
            
          </div>
          <hr />
          <div className="assets-popup-format">
            <h1>File Size:</h1>
            <p> {particularsimilarAssets.totalFileSize}</p>
          </div>
          <hr />
          <div className="assets-popup-format">
            <h1>Geometry:</h1>
            <p>Total Triangles : {particularsimilarAssets.totalTriangles}</p>
          </div>
          <hr />
          <div className="assets-popup-format">
            <h1>Vertices:</h1>
            <p>{particularsimilarAssets.vertices}</p>
          </div>
          <hr />
          <div className="assets-popup-format">
            <h1>UV Layers:</h1>
            <p>Yes</p>
          </div>
          <hr />
          <div className="assets-popup-format">
            <h1>Materials:</h1>
            <p>{particularsimilarAssets.materials}</p>
          </div>
          <hr />
          <div className="assets-popup-format">
            <h1>Rigged:</h1>
            <p>True</p>
          </div>
        </div>
      </div>
    );
}


const ModelViewer =({particularsimilarAssets})=>{
  const { currentUser } = useSelector((state) => state.user);
  // const [particularsimilarAssets, setParticularsimilarAssets] = useState([]);
  const [glbContent, setGlbContent] = useState(null);
  const userid = currentUser ? currentUser._id : null;
  const ID = useParams();
  // console.log("assetId", ID);
  const id = ID.assetId;
  const catId = ID.catID;

  


    useEffect(() => {
        axios
          .get(`${BASE_URL}/asset/${id}/view`)
          .then((response) => {
            const encryptedUrl = response.data.encryptedUrl;
     
            axios.post(`${BASE_URL}/asset/fetchGlbUrl`, { encryptedUrl }, { responseType: 'arraybuffer' })
              .then((glbResponse) => {
                const blob = new Blob([glbResponse.data], { type: 'model/gltf-binary' });
                const url = URL.createObjectURL(blob);
                setGlbContent(url);
              })
              .catch((error) => {
                // console.error('Error fetching GLB content:', error); 
              });
          })
          .catch((error) => { 
            // console.error('Error fetching encrypted URL:', error);
          });
      }, [id]);


      

    return(
        <div className="assets-popup-section1">
          <div className="assets-popup-section1-img">
              { glbContent  &&(
              <Model widthPercent={100} height={400} modelUrl={glbContent} />
            )}
          </div>
          <div className="assets-popup-description">
            <h2>Asset Description:</h2>
            <p>{particularsimilarAssets.description}</p>
          </div>
        </div>
    );
}

const AssetsPopup =()=>{
  const [particularsimilarAssets, setParticularsimilarAssets] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [similarAssets, setSimilarAssets] = useState([]);
  const [fileFormats , setFileFormats] = useState([])
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [error, setError] = useState(null);
  const userid = currentUser ? currentUser._id : null;
  const ID = useParams();
  // console.log("assetId", ID);
  const id = ID.assetId;
  const catId = ID.catID;
  // console.log("cat", catId);


  useEffect(() => {
    axios
      .get(`${BASE_URL}/asset/getAssets/${id}`)
      .then((response) => {
        const particularsimilarAssets = response.data;
        setParticularsimilarAssets(particularsimilarAssets);
        // console.log("dataassets", particularsimilarAssets);
      })
      .catch((error) => {
        // console.error("Error fetching category data:", error);
      });
  }, [id]);

  useEffect(() => {
    // Fetch category data from the backend
    axios
      .get(`${BASE_URL}/asset/getByCategory/${catId}`)
      .then((response) => {
        // console.log("ressssss", response);
        
        const similarAssets = response.data;
        setSimilarAssets(similarAssets);
        // console.log("data", similarAssets);
      })
      .catch((error) => {
        // console.error("Error fetching category data:", error);
      });
  }, []);


  useEffect(() => {
    const fetchFileFormats = async () => {
      try {
        const res = await axios.get(`${BASE_URL}/asset/${id}/files/download`);
        setFileFormats(res.data);
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching file formats", error);
        setError("Error fetching file formats");
      }
    };

    if (currentUser && id) {
      fetchFileFormats();
    }
  }, [id, currentUser]);

  const handledownloadClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  if (!particularsimilarAssets) {
    return <div>Loading...</div>;
  }
  
    return(
        <div id="assets-popup" className="assets-popup-container">
        {isPopupVisible && (
         <div className="download-popup">
           <div className="download-popup-content">
             <div className="download-popup-content-main">
               <h1>Download</h1>
               <button
                 className="download-close-button"
                 onClick={handleClosePopup}
               >
                 &times;
               </button>
             </div>
             <div className="download-popup-heading">
               <h1>Available Download</h1>
             </div>
             {Object.keys(fileFormats).map((format, index) => (
             <div className="download-popup-types" key={index}>
               <p>{format.toUpperCase()}</p>
               <p>{fileFormats[format].size}</p>
               <a
               className="download-popup-button"
               href={fileFormats[format].url}
               download
             >
               Download
             </a>
             </div>
             ))}
           </div>
         </div>
       )}
       <div
         className={`assets-popup-main  ${
           isPopupVisible ? "blur-background" : ""
         }`}
       >
         <div className="assets-pop-modelviewer">
         <ModelViewer particularsimilarAssets={particularsimilarAssets}/>
         <div className="assets-popup-similar-sets">
            <h2>Similar Assets:</h2>
            <div className="assets-popup-similar-sets1">
         {similarAssets.slice(0, 2).map((asset , index) =>(
         <SimilarAssets key={index} data={asset}/>
        
          ))}
          </div>
           </div>
         </div>
         <>
         <AssetDetails  particularsimilarAssets={particularsimilarAssets} ondownloadclick={handledownloadClick}/> 
         </>
       </div>
     </div>
    );
}

export default AssetsPopup;
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
//https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCh_R362DKfSGXP21eC0U8QFNqB1X0skkg",
  authDomain: "corlmart-8fbc7.firebaseapp.com",
  projectId: "corlmart-8fbc7",
  storageBucket: "corlmart-8fbc7.appspot.com",
  messagingSenderId: "762010260331",
  appId: "1:762010260331:web:2a69b2e02c6c7e337b722e",
  measurementId: "G-9YBFZR3NVQ"
};

// Initialize Firebase
export const Authapp = initializeApp(firebaseConfig);

const auth = getAuth();

 

import React from "react";
import { useState, useEffect } from "react";
import "../landingpage/landing01/Home.css"
import avatar1 from "../../image/suresh2.png";
import avatar2 from "../../image/amar3.svg";
import avatar3 from "../../image/kiran2 1.svg";
import avatar4 from "../../image/meta3.svg";
import avatar5 from "../../image/park 1.svg";
import avatar6 from "../../image/mani 1.svg";
import Background from "../../image/HomeBackground.svg";
import { Link, useNavigate } from "react-router-dom";

function CreateHeader({ onBuyNowClick }){
  const [currentAvatar, setCurrentAvatar] = useState(0);
  const avatars = [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    
  ];
  const navigate = useNavigate();

  const handleBuyNowClick = () => {

    navigate("/landingmain")
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAvatar((prevAvatar) => (prevAvatar + 1) % avatars.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [avatars.length]);
  return (
    <div className="landing1-container">
      <div className="landing1-main">
        <div className="landing1-left">
          <h2>Discover, Explore & Buy 3D Character</h2>
          <h1>
          Bring your Imagination <br/>to Life!
          </h1>
          <p>
          Upload your photo & experience <br/>
          it in a whole new way
          </p>
          <button onClick={handleBuyNowClick}>Explore</button>
        </div>
        <div className="landing1-right">
          <div className="landing1-outline">
            <img src={Background} alt="background" />

            <div className="landing1-avatar">
              {avatars.map((avatar, index) => (
                <img
                  key={index}
                  src={avatar}
                  alt={`Avatar ${index + 1}`}
                  className={`absolute transition-opacity duration-1000 ${
                    index === currentAvatar ? "opacity-100" : "opacity-0"
                  }`}
                  // style={{
                  //   width: "auto", // Make sure the width is 100%
                  //   height: "auto", // Maintain aspect ratio
                  //   position: "absolute",
                  //   top: "50%", // Position in the middle vertically
                  //   left: "50%", // Position in the middle horizontally
                  //   transform: "translate(-50%, -50%)", // Center the image
                  //   zIndex: avatars.length - index, // Ensure the order of overlapping
                  // }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateHeader;

import React, { useState ,useEffect} from 'react';
import "./adminPurchase.css";
import SideBar from '../../common/sideBar';
import axios from 'axios';
import { BASE_URL } from '../../../../constant';
import { useSelector } from 'react-redux';
 
const AdminPurchase = () => {
  const [activeItem, setActiveItem] = useState("purchasehistory");
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { currentUser } = useSelector((state) => state.user);
 
 
  const handleIndexClick = (index) => {
    setSelectedData(purchaseHistory[index]);
    setShowPopup(true);
  };
 
 
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };
 
 
  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await fetch(`${BASE_URL}/payment/getAllPurchaseHistory`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${currentUser.token}`
          }
        });
 
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
 
        const responseData = await response.json(); // Convert response body to JSON
        console.log(responseData.message); // Log the JSON data
  setPurchaseHistory(responseData.message);
        // You might want to setPurchaseHistory(responseData.assets);
 
      } catch (error) {
        console.error("Error fetching assets:", error);
      }
    };
 
    fetchAssets();
  }, [currentUser.token]);
 
 
// console.log(selectedData.createdTime);
console.log(purchaseHistory);
 
 
  return (
    <div className='admin-purchase-container'>
      <SideBar setActiveItem={handleItemClick} activeItem={activeItem} />
      <div className='admin-purchase-main'>
        <div className='admin-purchase-content'>
          <div className='admin-purchase-content-heading'>
            <h1>Purchase History</h1>
          </div>
          <div className='admin-purchase-content-details'>
            {purchaseHistory.map((purchase, purchaseIndex ) => (
              <div key={purchaseIndex}>
                {purchase.usercart.map((cartItem, cartIndex) => (
                  <div key={cartIndex} onClick={() => handleIndexClick(purchaseIndex)}>
                    <div className='admin-purchase-content-details-table'>
                      <h1>{cartIndex}</h1> {/* Index here */}
                      <div className='admin-purchase-content-details-table-content' >
                        <h1>Asset Name:</h1>
                        <p>{cartItem.productId.assetName}</p>
                      </div>
                      <div className='admin-purchase-content-details-table-content'>
                        <h1>ID:</h1>
                        <p>{purchase.razorpay_order_id}</p>
                      </div>
                      <div className='admin-purchase-content-details-table-content'>
                        <h1>Purchase Date:</h1>
                        <p>{purchase.date.split(" ").splice(1,3).join(" ")}</p>
                      </div>
                      <div className='admin-purchase-content-details-table-content'>
                        <h1>Price:</h1>
                        <p>{cartItem.productId.price}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="admin-purchase-popup">
          <div className="admin-purchase-popup-content">
            <span className="admin-purchase-popup-close" onClick={() => setShowPopup(false)}>×</span>
            <h2>Buyer Name:{selectedData.userDetails.userName}</h2>
            <p>Email Id: {selectedData.userDetails.email}</p>
            <p >Order ID:{selectedData.razorpay_order_id}</p>
            <p>Transaction ID:{selectedData.razorpay_payment_id}</p>
            <p>Purchase Date: {selectedData.date.split(" ").splice(1,3).join(" ")}</p>
            <p>Price: {selectedData.usercart[0].productId.price}</p>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default AdminPurchase;
import React, { useState, useRef, useEffect } from 'react';
import "./landing03.css";
import backgroundImage from "../../image/bg02.svg"
import { BASE_URL } from "../../../../constant";
import ReactPlayer from 'react-player';
import { FaPlay, FaPause } from 'react-icons/fa';
import { BsFullscreen } from 'react-icons/bs';
// import demo from "../../images/category/corlmart.mp4"

const Landing03 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [feedback_text, setFeedbackTask] = useState('');
  const playerRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const containerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting && !hasPlayed) {
        setPlaying(true);
        setHasPlayed(true);
      } else if (!entry.isIntersecting) {
        setPlaying(false);
      }
    };

    const observerOptions = {
      threshold: 0.5, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentContainer = containerRef.current;

    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, [hasPlayed]);

  

  const handleVideoEnd = () => {
    setPlaying(false);
  };

  const handlePlayButtonClick = () => {
    setPlaying(true);
  };

  const handlePauseButtonClick = () => {
    setPlaying(false);
  };

  const handleFullscreen = () => {
    if (playerRef.current) {
      playerRef.current.wrapper.requestFullscreen();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const form = { name, phone, email, feedback_text };
    console.log(form);
    await fetch(`${BASE_URL}/feedback/userFeedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
    
      .then(async (response) => {
        console.log("Response",response);
        const responseData = await response.json();
        if (!response.ok) {
          throw new Error(responseData.message || "Message not sent");
        }
        console.log(responseData);
        return responseData;
        
      })
      .then((data) => {
        console.log("Message sent");
        setName('');
        setEmail('');
        setPhone('');
        setFeedbackTask('');
      })
      .catch((error) => {
        console.error("Error:", error);
        alert(error.message || "Please try again.");
      });
  };

  return (
    <div className="container02">
      <div className="video-main">
        <div className='video-contain'>
          <div className="video-container">
            <div className="video-player-container">
            <ReactPlayer
            
                ref={playerRef}
                url="https://s3.ap-south-1.amazonaws.com/corllel.com/RIVW+Industry+Website.mp4"
                width="100%"
                height="100%"
                playing={playing}
                onEnded={handleVideoEnd}
                
                
              />
   
              {!videoEnded && playing ? (
                <div className="pause-icon-overlay" onClick={handlePauseButtonClick}>
                  <FaPause size={30} />
                </div>
              ) : (
                <div className="play-icon-overlay" onClick={handlePlayButtonClick}>
                  <FaPlay size={30} />
                </div>
              )}
              <div className='fullscreen-icon-overlay' onClick={handleFullscreen}>
                <BsFullscreen size={15} />
              </div>
            </div>
          </div>

          <div className="form-container">
            <h1>Tell Us About Your Idea</h1>
            <form onSubmit={handleSubmit} className="form-con">
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Your Name"
                className="form-border"
                required
              />
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email Id"
                className="form-border"
                required
              />
              <input
                type="tel"
                name="phoneNumber"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter Your Mobile Number"
                className="form-border"
                required
              />
              <textarea
                name="message"
                value={feedback_text}
                onChange={(e) => setFeedbackTask(e.target.value)}
                placeholder="What Design Task Do You Have?"
                className="form-border-long"
                required
              />
              <button type="submit" className="form-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default Landing03;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const { currentUser, loading, isLoggedIn } = useSelector((state) => state.user);

  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator
  }

  // Check if the user is logged in and if the user is an admin
  return isLoggedIn && currentUser?.isAdmin ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;

import React, { useState, useEffect } from "react";
import "./profile.css";
import img1 from "../../image/profilefox.svg";
import img5 from "../../image/007.svg";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useFunc } from "../../../../redux/user/helperFunctions";
import {
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
} from "../../../../redux/user/userSlice";
import { BASE_URL } from "../../../../constant";
import axios from "axios";

function HistoryCard({order }) {

  const [isPopupVisible, setPopupVisible] = useState(false)
  const orderDate = new Date(order.date);
  const { currentUser } = useSelector((state) => state.user);
  const assetId = order.usercart[0]?.productId?._id || "Not available";
  const formattedDate = orderDate.toDateString();
  const price = order.usercart[0]?.productId?.price || "Price not available";
  const name = order.usercart[0]?.productId?.assetName || "Not available";
  const imgUrl = order.usercart[0]?.productId?.signedImageUrl ||"Loading"
  const [fileFormats, setFileFormats] = useState({});

  const handledownloadClick = () => {
   
    setPopupVisible(true); // Show the popup when "Buy Now" is clicked
  };

  const handleClosePopup = () => {
    setPopupVisible(false); 
    
  };

  useEffect(() => {
    const fetchFileFormats = async () => {
      try {
        if (currentUser && assetId) {
          const res = await axios.get(`${BASE_URL}/asset/${assetId}/files/download`, {
            // headers: {
            //   Authorization: `Bearer ${currentUser.token}`
            // }
          });
          setFileFormats(res.data); 
          // console.log(res.data);
        }
      } catch (error) {
        // console.error("Error fetching file formats", error);
      }
    };
  
    fetchFileFormats();
  }, [assetId, currentUser]); 
  
  
  return (
    <div className="card-profile">
      {isPopupVisible && (
        <div className="download-popup">
          <div className="download-popup-content">
            <div className="download-popup-content-main">
              <h1>Download</h1>
              <button
                className="download-close-button"
                onClick={handleClosePopup}
              >
                &times;
              </button>
            </div>
            <div className="download-popup-heading">
              <h1>Available Download</h1>
            </div>
            {Object.keys(fileFormats).map((format, index) => (
            <div className="download-popup-types" key={index}>
              <p>{format.toUpperCase()}</p>
              <p>{fileFormats[format].size}</p>
               <a
              className="download-popup-button"
              href={fileFormats[format].url}
              download
            >
              Download
            </a>
            </div>
            ))}
           
          </div>
        </div>
      )}
      <div
        className={`conatiner-profile  ${
          isPopupVisible ? "blur-background" : ""
        }`}
      ></div>
      <div className="card-profile-main">
       
          <div className="card-profile-main-content" key={order._id}>
          {Object.keys(fileFormats).filter(format => format === 'jpg' || format === 'png').map((format, index) => (
  <div className="image-profile" key={index}>
    <img src={fileFormats[format].url} alt={name} />
  </div>
))}
            <div className="content-profile">
              <div className="title-profile01">
                <p>{name}</p>
              </div>
              <div className="title-profile02">
                <p>Purchased On:</p>
                <h3>{formattedDate}</h3>
              </div>
              <div className="title-profile03">
                <img src={img5} alt="im" />
                <h3>{price}</h3>
                <div className="assets-popup-buy">
                <button onClick={handledownloadClick}>Download</button>
                </div>
              </div>
            </div>
          </div>
    
      </div>
    </div>
  );
}

function ProfileCard() {
  const { currentUser } = useSelector((state) => state.user);

  const [showPopup, setShowPopup] = useState(false); // State for managing popup visibility
  const { handleLogout } = useFunc();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log(currentUser);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Check if currentUser exists before making the request
        if (!currentUser) return;

        const res = await fetch(
          `${BASE_URL}/user/getParticularUser?id=${currentUser._id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
            },
          }
        );
        if (!res.ok) {
          throw new Error("Failed to fetch user details");
        }
        const data = await res.json();
        if (data.status !== 200) {
          throw new Error(data.message);
        }
        // Update state or dispatch action to store user details
      } catch (error) {
        // console.error("Error:", error);
        // Handle error
      }
    };

    fetchUserDetails();
  }, [currentUser]);

  const routeChange = () => {
    let path = "/login";
    navigate(path);
  };

  // console.log(Response.user);
  const handleDeleteUser = async () => {
    setShowPopup(false);
    try {
      dispatch(deleteUserStart());

      const res = await fetch(`${BASE_URL}/user/delete`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${currentUser.token}`, // Include user token for authentication
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        throw new Error(errorData.message || "Failed to delete user");
      }

      const data = await res.json();
      dispatch(deleteUserSuccess(data));
      navigate("/login");
    } catch (error) {
      // console.error("Error:", error);
      dispatch(deleteUserFailure(error.message));
    }
  };

  const handleDeleteAccount = async () => {
    setShowPopup(true);
  };

  return (
    <div className="user-profile">
      <div className="profile-item01">
        <h4>Name</h4>
        <p>{currentUser?.name}</p>
      </div>
      <div className="profile-item01">
        <h4>Email</h4>
        <p>{currentUser?.email}</p>
      </div>
      <div className="profile-item01">
        <h4>Phone Number</h4>
        <p>{currentUser?.phone}</p>
      </div>
      <div className="profile-item02">
        <p onClick={handleDeleteAccount}>Delete Account</p>
        {showPopup && (
          <div className="popup">
            <div className="popup-content">
              <h3>Are you sure you want to delete your account?</h3>
              <div className="popup-btn">
                <button onClick={() => handleDeleteUser()}>Delete</button>
                <button onClick={() => setShowPopup(false)}>Cancel</button>
              </div>
            </div>
          </div>
        )}
        <button
          onClick={() => {
            routeChange();
            handleLogout();
          }}
        >
          Log Out
        </button>
      </div>
      {showPopup && <div className="backdrop"></div>} {/* Backdrop */}
    </div>
  );
}

function HistoryList() {
  const [userOrders, setUserOrders] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const userid = currentUser ? currentUser._id : null;
  // console.log(userid);
  

  useEffect(() => {
    const fetchUserOrders = async () => {
      try {
        // Check if currentUser exists before making the request
        if (!currentUser ) return;

        const res = await fetch(
          `${BASE_URL}/payment/getParticularUserHistory?userId=${userid}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${currentUser.token}`,
            },
          }
        );

        if (!res.ok) {
          throw new Error("Failed to fetch user orders");
        }

        const data = await res.json();
        setUserOrders(data.message); // Assuming the data structure from the backend
        // console.log(data.message);
      } catch (error) {
        // console.error("Error fetching user orders:", error);
      }
    };

    fetchUserOrders();
  }, [currentUser]);




  
  

  return (
    <div className="container-profile">
       
      <div className="main-profile01">
        <div className="container-profile-main">
          <div className="main-heading-profile">
            <h1>View Order Details</h1>
            <div className="container-profile-main01">
              {userOrders.length > 0 ? (
                userOrders.map((order) => (
                  <React.Fragment key={order._id}>
                    <HistoryCard order={order} />
                    <hr />
                  </React.Fragment>
                ))
              ) : (
                <p><span style={{color:"white"}}>No orders have been placed.</span></p>
              )}
            </div>
          </div>
          <div className="main-profile02">
            <div className="main-heading-profile">
              <h1>Personal Details</h1>
              <div className="container-profile-main02">
                <ProfileCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HistoryList;

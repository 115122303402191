import React, { useState, useEffect, useRef } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function loadGLTFModel(scene, glbPath, options) {
  const { receiveShadow, castShadow } = options;
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader();
    loader.load(glbPath, (gltf) => {
      const obj = gltf.scene;
      obj.name = "dinosaur";
      obj.position.set(0, 0, 0);
      obj.receiveShadow = receiveShadow;
      obj.castShadow = castShadow;
      scene.add(obj);
      obj.traverse(function (child) {
        if (child.isMesh) {
          child.castShadow = castShadow;
          child.receiveShadow = receiveShadow;
        }
      });
      resolve(obj);
    }, undefined, reject);
  });
}

const easeOutCirc = (x) => Math.sqrt(1 - Math.pow(x - 1, 4));

const Model = ({ modelUrl }) => {
  const refContainer = useRef();
  const [loading, setLoading] = useState(true);
  const [renderer, setRenderer] = useState();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      const { current: container } = refContainer;
      if (container && !renderer) {
        const scW = container.clientWidth;
        const scH = container.clientHeight;
        const renderer = new THREE.WebGLRenderer({
          antialias: true,
          alpha: true
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(scW, scH);
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.shadowMap.enabled = true;
        renderer.setClearColor(0xE0E0E0, 1);
        container.appendChild(renderer.domElement);
        setRenderer(renderer);

        const scene = new THREE.Scene();
        const aspect = scW / scH;
        const camera = new THREE.OrthographicCamera(-5 * aspect, 5 * aspect, 5, -5, 0.01, 2000);
        const target = new THREE.Vector3(0, 1.2, 0);
        const initialCameraPosition = new THREE.Vector3(20 * Math.sin(0.2 * Math.PI), 10, 20 * Math.cos(0.2 * Math.PI));

        camera.position.copy(initialCameraPosition);
        camera.lookAt(target);

        const ambientLight = new THREE.AmbientLight(0xcccccc, 1);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(5, 10, 7.5);
        directionalLight.castShadow = true;
        directionalLight.shadow.mapSize.width = directionalLight.shadow.mapSize.height = 1024;
        directionalLight.shadow.camera.near = 0.5;
        directionalLight.shadow.camera.far = 500;
        scene.add(directionalLight);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.autoRotate = true;
        controls.target = target;
        controls.enableDamping = true; // Enable smooth transitions
        controls.dampingFactor = 0.05;
        controls.minDistance = 0.1; // Minimum zoom distance
        controls.maxDistance = 500; // Maximum zoom distance
        controls.update();

        loadGLTFModel(scene, modelUrl, { receiveShadow: true, castShadow: true }).then(() => {
          animate();
          setLoading(false);
        });

        let req = null;
        let frame = 0;
        const animate = () => {
          req = requestAnimationFrame(animate);
          frame = frame <= 100 ? frame + 1 : frame;

          if (frame <= 100) {
            const p = initialCameraPosition;
            const rotSpeed = -easeOutCirc(frame / 120) * Math.PI * 20;

            camera.position.y = 10;
            camera.position.x = p.x * Math.cos(rotSpeed) + p.z * Math.sin(rotSpeed);
            camera.position.z = p.z * Math.cos(rotSpeed) - p.x * Math.sin(rotSpeed);
            camera.lookAt(target);
          } else {
            controls.update();
          }
          renderer.render(scene, camera);
        };

        initialized.current = true;
        return () => {
          cancelAnimationFrame(req);
          renderer.dispose();
        };
      }
    }
  }, [renderer, modelUrl]);

  useEffect(() => {
    const handleResize = () => {
      const { current: container } = refContainer;
      if (container && renderer) {
        const scW = container.clientWidth;
        const scH = container.clientHeight;

        renderer.setSize(scW, scH);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [renderer]);

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }} ref={refContainer}>
      {loading && <span style={{ left: "50%", top: "50%", position: "absolute" }}>Loading...</span>}
    </div>
  );
};

export default Model;
import React, { useState, useRef, useEffect, useContext } from 'react';
import "../landingpage/landing03.css"
import backgroundImage from "../../image/bg02.svg"
import { BASE_URL } from "../../../../constant";
import ReactPlayer from 'react-player';
import { FaPlay, FaPause } from 'react-icons/fa';
import { BsFullscreen } from 'react-icons/bs';
import { toast } from "react-toastify";
// import demo from "../../images/category/corlmart.mp4"

import frontpic from "../../image/frontFace.svg";
import leftpic from "../../image/leftFace.svg";
import rightpic from "../../image/rightFace.svg";
import fullpic from "../../image/fullFace.svg";
import backpic from "../../image/backpose.svg";
import fullpres from "../../image/fullpersp.svg";
import { FaUpload, FaTimes } from 'react-icons/fa';
import { ImageContext } from "../landingpage/ImageContent"
import axios from "axios"


const ImageUploadSection = ({ title, placeholderImage, uploadText }) => {
  const { images, updateImage, clearAllImages } = useContext(ImageContext);
  const imageKey = title.replace(/\s+/g, '');

  const handleUploadClick = () => {
    document.getElementById(imageKey).click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      updateImage(imageKey, file, file.name);
    }
  };

  const handleRemoveImage = () => {
    clearAllImages(imageKey);
    document.getElementById(imageKey).value = null;
  };

  const uploadedImage = images[imageKey]?.image ? URL.createObjectURL(images[imageKey].image) : null;

  return (
    <div className="flex flex-col rounded-lg items-center py-3 w-custom-374 h-custom-365 text-center"style={{ backgroundImage: "url('/backgroundpic.svg')", backgroundRepeat: "no-repeat" }}>
      <h1 className="text-lg font-normal mb-4 font-Oxygen text-white">{title}</h1>
      <div className="pl-2 pr-2 pb-2 rounded-md mb-4">
        <img
          src={uploadedImage || placeholderImage}
          alt={title}
          className="w-32 h-32 object-cover mx-auto border-0 p-1"
        />
      </div>
      <button type='button' className="flex items-center bg-purple-600 text-white py-2 px-4 rounded-md mb-4" onClick={handleUploadClick}>
        <FaUpload className="mr-2" />
        Upload
      </button>
      <input
        id={imageKey}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <div className="flex items-center bg-gray-100 py-2 px-4 rounded-md text-gray-500">
        {images[imageKey]?.imageName || uploadText}
        {images[imageKey] && <FaTimes className="pl-1 cursor-pointer" onClick={handleRemoveImage} />}
      </div>
    </div>
  );
};


const CreateModel = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [designTask, setFeedbackTask] = useState('');
  const playerRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const containerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const { images, clearAllImages } = useContext(ImageContext);

  useEffect(() => {
    const observerCallback = ([entry]) => {
      if (entry.isIntersecting && !hasPlayed) {
        setPlaying(true);
        setHasPlayed(true);
      } else if (!entry.isIntersecting) {
        setPlaying(false);
      }
    };

    const observerOptions = {
      threshold: 0.5, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentContainer = containerRef.current;

    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.unobserve(currentContainer);
      }
    };
  }, [hasPlayed]);

  

  const handleVideoEnd = () => {
    setPlaying(false);
  };

  const handlePlayButtonClick = () => {
    setPlaying(true);
  };

  const handlePauseButtonClick = () => {
    setPlaying(false);
  };

  const handleFullscreen = () => {
    if (playerRef.current) {
      playerRef.current.wrapper.requestFullscreen();
    }
  };


  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const sections = [
    { title: 'Front Facing Photo', placeholderImage: frontpic, uploadText: 'Upload Photo 1' },
    { title: 'Left Facing Photo', placeholderImage: leftpic, uploadText: 'Upload Photo 2' },
    { title: 'Right Facing Photo', placeholderImage: rightpic, uploadText: 'Upload Photo 3' },
    { title: 'Full Front Facing Photo', placeholderImage: fullpic, uploadText: 'Upload Photo 4' },
    { title: 'Full Back Facing Photo', placeholderImage: backpic, uploadText: 'Upload Photo 5' },
    { title: 'Full Perspective Photo', placeholderImage: fullpres, uploadText: 'Upload Photo 6' },
  ];

 const handleSubmit = async (e) => {
  e.preventDefault();

  if (!isChecked) {
    toast("Please agree to the terms and conditions by checking the checkbox.", {
      icon: "⚠️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    return;
  }

  console.log('Submitting form...');
  console.log('Name:', name);
  console.log('Email:', email);
  console.log('Phone Number:', phoneNumber);
  console.log('Design Task:', designTask);
  console.log('Images:', images); // Log images context

  // const requiredImages = [
  //   'FrontFacingPhoto',
  //   'LeftFacingPhoto',
  //   'RightFacingPhoto',
  //   'FullFrontFacingPhoto',
  //   'FullBackFacingPhoto',
  //   'FullPerspectivePhoto',
  // ];

  // const allImagesUploaded = requiredImages.every(imageKey => images[imageKey] && images[imageKey].image);

  // if (!allImagesUploaded) {
  //   toast("Upload all image to create", {
  //     icon: "👏",
  //     style: {
  //       borderRadius: "10px",
  //       background: "#333",
  //       color: "#fff",
  //     },
  //   });
  //   return;
  // }

  const formData = new FormData();
  formData.append('name', name);
  formData.append('emailId', email);
  formData.append('phoneNumber', phoneNumber);
  formData.append('designTask', designTask);

  Object.keys(images).forEach((imageKey) => {
    const imageObject = images[imageKey];
    if (imageObject && imageObject.image) {
      formData.append('files', imageObject.image, imageObject.imageName);
    }
  });

  console.log('FormData:', formData); // Log FormData for debugging

  try {
    const response = await axios.post(`${BASE_URL}/character/createcharacterAsset`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log('Response:', response); // Log server response

    if (response.status === 200) {
      if(response.data.status===400){
        toast("Upload atleast 1 file", {
          icon: "👏",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });

      }else{
      toast("We will respond back to you within 3 to 5 business working days.", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setName('');
      setEmail('');
      setPhone('');
      setFeedbackTask('');
      setIsChecked(false);

      // Clear images context
      clearAllImages();
      window.location.reload();
      
    } }else {
      console.error('Failed to upload images:', response.statusText);
      toast("Failed to send message", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  } catch (error) {
    console.error('Error uploading images:', error.message);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    toast("Error uploading images", {
      icon: "👏",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  }
};

  

  return (
    <div className="container02">
      <form onSubmit={handleSubmit} >
      <div className="video-main1">
        <div className='video-contain1 '>
            <div className='lg:w-3/6 md:w-full'>
        <div className='flex items-center justify-center pt-8 pb-4  '>
            <h1 className='text-3xl font-Montaga font-normal mb-4 bg-custom-gradient text-transparent bg-clip-text inline-block'>
                Photo Upload Guide
            </h1>
        </div>
        <div>
            <ul className='list-disc ml-5 text-white py-4 text-base font-Oxygen'>
                <li  className=' pb-4'>
                A strong image or photo is essential For a successful generation procedure. When taking a photo, there are five important things to keep in mind.
                </li>
                <li  className=' pb-4'>
                A well-lit, crisp picture of a whole head facing front with nothing blocking the facial characteristics should be Important. 
                </li>
                <li  className=' pb-4'>
                The image's film format can be one of the following: jpg, png.
                </li>
                <li  className=' pb-4'>
                Although there are some differences in terms of resolution and cropping, the idea of an ideal image is essentially the same regardless of the technique used to generate the character. 
                </li>
                <li  className=' pb-4'>
                Pictures with spectacles will not Be accepted.
                </li>
            </ul>
        </div>
        </div>

          <div className="form-container">
            <h1>Tell Us About Your Idea</h1>
            <div className='form-con'>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Your Name"
                className="form-border"
                required
              />
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email Id"
                className="form-border"
                required
              />
              <input
                type="tel"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter Your Mobile Number"
                className="form-border"
                required
              />
              <textarea
                name="message"
                value={designTask}
                onChange={(e) => setFeedbackTask(e.target.value)}
                placeholder="What Design Task Do You Have?"
                className="form-border-long"
                required
              />
              </div>
            
          </div>
        </div>
      </div>
      <div className=' py-12 flex flex-col items-center '>
      <div className="flex flex-wrap justify-between items-center pb-6 gap-4">
        {sections.map((section, index) => (
          <ImageUploadSection
            key={index}
            title={section.title}
            placeholderImage={section.placeholderImage}
            uploadText={section.uploadText}
          />
        ))}
      </div>
      <div className='flex flex-col items-center gap-4' style={{ backgroundImage: "url('/bgcheck.svg')", backgroundRepeat: "no-repeat" ,borderRadius:"10px",padding:"10px" ,backgroundSize:"cover"}}>
        <div className='mt-4 flex text-white text-justify'>
          <label className='custom-checkbox gap-2 flex items-start'>
            <input type='checkbox' className='mr-1 mt-2  w-8" required' onChange={handleCheckboxChange} />
            <span className='font-Oxygen text-base font-thin'>
              We utilize the photo/image shared by users to create the 3D character. Please note that the responsibility for obtaining appropriate permission for these
              photos lies with the user. We do not hold any responsibility for the use of third-party photos, while creating 3D characters. Users are solely responsible for
              ensuring that they have the necessary rights for those images and agree to indemnify and hold harmless (Rivw) for any claims, damages, or liabilities arising
              from the use of such photos.
            </span>
          </label>
        </div>
        <div>
          <button type='submit' className="bg-custom-gradient text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors" >
            Create
          </button>
        </div>
      </div>
    </div>
    </form>
    </div>
  );  
};

export default CreateModel;

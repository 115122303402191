import React from 'react'
import Our3DModels from './landing02'
import Landing03 from './landing03'
import Landing01 from './landing01/Home'

const LandingMain = () => {
  return (
    <div className='landingMain'>
      <Landing01/>
        <Our3DModels/>
        
        <Landing03/>

    </div>
  )
}

export default LandingMain

// import React, { useState ,useRef, useEffect} from 'react';
// import Our3DModels from './landing02';
// import Landing03 from './landing03';
// import Landing01 from './landing01/Home';
// import Landing04 from './landing04';
// import Landing05 from './landing05';
// // import Landing04 from './landing04';

// const LandingMain = () => {
//   const [showLanding04, setShowLanding04] = useState(false);
//   const landing05Ref = useRef(null);

//   useEffect(() => {
//     if (showLanding04 && landing05Ref.current) {
//       landing05Ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
//     }
//   }, [showLanding04]);

//   const handleBuyNowClick = () => {
//     setShowLanding04(true);
//   };


//   return (
    
//     <div className='landingMain'>
//       <Landing01 onBuyNowClick={handleBuyNowClick} />
//       {showLanding04 ? (
//       <>
     
//       <div ref={landing05Ref}>
//             <Landing05 />
//           </div>
//           <Landing04/>
//         </>
        
//       ) : (
//         <>
//         <Our3DModels />
//         <Landing03 />
//         </>
//       )}
      
//     </div>
   
//   );
// };
// export default LandingMain;
